import React, { useEffect } from 'react'
import { List, Radio, Space, Toast } from 'antd-mobile'

import EditDrawer from './EditDrawer'

export default function UserGender(props) {
  const { gender, onChange } = props
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(gender)

  const genderMap = {
    male: '男',
    female: '女',
    keep_secret: '保密',
  }

  useEffect(() => {
    setValue(gender)
  }, [open])

  const handleChange = (value) => {
    setValue(value)
  }

  const handleSave = async () => {
    const { code } = await onChange({ gender: value })
    if (code === 200) {
      setOpen(false)
    }
  }

  function handleClose() {
    setValue(gender)
    setOpen(false)
  }

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => {
          if (gender !== 'keep_secret') {
            Toast.show({ icon: 'fail', content: '性别设置好后无法修改' })
            return
          }
          setOpen(true)
        }}
        extra={<span>{genderMap[gender]}</span>}>
        <span style={{ color: 'var(--theme-text-color)' }}>性别</span>
      </List.Item>

      <EditDrawer
        height="50%"
        onClickSave={handleSave}
        title="设置性别"
        anchor="bottom"
        open={open}
        onClose={handleClose}>
        <div style={{ textAlign: 'center', fontSize: '10px', color: 'red', marginLeft: '16px' }}>
          性别设置好后无法修改，请谨慎选择
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Radio.Group value={value} defaultValue={gender} onChange={handleChange}>
            <Space direction="horizontal" style={{ '--gap': '30px' }}>
              <Radio value="male">男</Radio>
              <Radio value="female">女</Radio>
              {/* <Radio value="keep_secret">保密</Radio> */}
            </Space>
          </Radio.Group>
        </div>
      </EditDrawer>
    </>
  )
}
