import React from 'react'
import { Form, List, Input, Toast } from 'antd-mobile'

import EditDrawer from './EditDrawer'

export default function UserFakeLike(props) {
  const { fake_likes, onChange } = props
  const [open, setOpen] = React.useState(false)

  const formRef = React.useRef(null)

  const handleSave = () => {
    formRef.current
      .validateFields()
      .then(async (value) => {
        // 验证通过后进入
        const { code } = await onChange({ fake_likes: Number(value.fake_likes) })
        if (code === 200) {
          setOpen(false)
        }
      })
      .catch((err) => {
        // 验证不通过时进入
        Toast.show({ icon: 'fail', content: '输入内容有误' })
      })
  }

  function handleClose() {
    formRef.current?.setFieldsValue({ fake_likes: fake_likes })
    setOpen(false)
  }

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => setOpen(true)}
        extra={<span>{fake_likes}</span>}>
        <span style={{ color: 'var(--theme-text-color)' }}>伪装魅力值（展示与实际值较小的）</span>
      </List.Item>

      <EditDrawer
        height="50%"
        onClickSave={handleSave}
        title="设置伪装魅力值"
        anchor="bottom"
        open={open}
        onClose={handleClose}>
        <div>
          <span style={{ fontSize: '10px', color: 'red', marginLeft: '16px' }}>
            展示给对方的是 你实际魅力值和当前设置的伪装魅力值 较小的一个，输入小数将取整
          </span>
        </div>

        <div>
          <span style={{ fontSize: '10px', marginLeft: '16px' }}>
            若要关闭该功能展示实际的魅力值，设置个很大的值即可 例如：9999999
          </span>
        </div>

        <Form ref={formRef}>
          <Form.Item
            name="fake_likes"
            initialValue={fake_likes}
            rules={[
              {
                min: 1,
                max: 99999,
                type: 'number',
                message: '请输入1-99999的数字',
                transform: (value) => {
                  return Number(value)
                },
              },
            ]}>
            <Input />
          </Form.Item>
        </Form>
      </EditDrawer>
    </>
  )
}
