export const updateLogsData = [
  {
    title:'即将上线',
    contents: ["⭐️ 优化语音条体验 ⭐️"]
  },
  {
    title:'2024.10.30',
    contents: ["【新增】寻人公告认领后增加直接开始聊天入口", "【优化】和虚拟人物聊天、寻人公告体验"],
  },
  {
    title:'2024.10.28',
    contents: ["【新增】和虚拟人物聊天", "【新增】寻人公告"],
  },
  {
    title:'2024.10.21',
    contents: ["【优化】自动处罚用户违规行为逻辑"],
  },
  {
    title:'2024.10.17',
    contents: ["【优化】刚建立的聊天判断消息能否发出", "【优化】点击已被删除的聊天页面状态更新", "【优化】网页加载速度，emoji样式"],
  },
  {
    title:'2024.10.16',
    contents: ["【新增】对方删除对话后保留对话入口", "【修复】随机匹配建立的聊天对方头像可能是空白"],
  },
  {
    title:'2024.10.15',
    contents: ["【新增】位置信息自动更新，可选择显示或隐藏", "【优化】封禁用户的样式展示"],
  },
  {
    title:'2024.10.14',
    contents: ["【完善】封禁用户策略"],
  },
  {
    title:'2024.10.12',
    contents: ["【完善】强制绿色模式下判断消息能不能发出", "【完善】刷魅力值检查机制"],
  },
  {
    title:'2024.10.11',
    contents: ["【新增】扔瓶子增加魅力值限制，减少人工审核负担", "【新增】新建聊天最多发3条消息，需等对方回复后才可继续发送，防止频繁的骚扰"],
  },
  {
    title:'2024.9.30',
    contents: ["【完善】实时智能分析增加/扣除魅力值", "【完善】敏感词库"],
  },
  {
    title:'2024.9.29',
    contents: ["【优化】随机匹配短时间不匹配到相同的人", "【完善】敏感词库"],
  },
  {
    title:'2024.9.28',
    contents: ["【新增】服务器配置升级", "【新增】捞个在线被捞到次数限制，防止过多打扰", "【完善】敏感词库"],
  },
  {
    title:'2024.9.27',
    contents: ["【完善】魅力值机制", "【新增】设置-游戏标签可选项", "【完善】捞瓶子避免捞到相同内容的", "【完善】敏感词库"],
  },
  {
    title:'2024.9.24',
    contents: ["【新增】绑定手机号/微信直接登录", "【新增】清流分区", "【完善】敏感词库"],
  },
  {
    title:'2024.9.22',
    contents: ["【新增】更新的头像或昵称增加二次人工审核", "【完善】敏感词库"],
  },
  {
    title:'2024.9.20',
    contents: ["【新增】更新日志", "【完善】敏感词库", "【修复】夜间模式下样式完善", "【新增】英语模式下支持标点符号"],
  },
  {
    title:'2024.9.19',
    contents: ["【新增】限制魅力值过低的用户匹配次数", "【新增】举报内容经过人工审核，通知审核结果"],
  },
  // {
  //   title:'2023.6.24',
  //   contents: ["【新增】支持分区，只能匹配到同一分区的用户"],
  // },
  // {
  //   title:'2023.6.22',
  //   contents: ["【新增】聊天页面 - 增加 来个段子 功能"],
  // },
  // {
  //   title:'2023.6.19',
  //   contents: ["【新增】设置页 - 注销账号支持",  "【完善】敏感词库"],
  // },
  // {
  //   title:'2023.6.16',
  //   contents: ["【新增】随机匹配成功后先给对方信息，不直接创建聊天",  "【新增】第一次匹配上聊天前6句消息强制使用绿色模式", "【完善】敏感词库"],
  // },
  // {
  //   title:'2023.6.14',
  //   contents: ["【升级】系统机器识别广告引流诈骗等违规内容", "【新增】性别选定后不支持修改", "【新增】服务条款 - 违规处理公示", "【完善】服务条款中部分细则", "【完善】敏感词库"],
  // },
  // {
  //   title:'2023.6.9',
  //   contents: ["【修复】查看聊天后该聊天仍有未读消息的问题", "【修复】可能出现未读消息是自己发的消息的问题"],
  // },
  // {
  //   title:'2023.6.5',
  //   contents: ["【修复】选择表情后语音键未切换成发送键", "【修复】清空输入框时发送键未切换成语音键", "【修复】撤回消息菜单可能无法唤起的问题", "【新增】电脑端右键消息，可进行复制和撤回操作"],
  // },
  // {
  //   title:'2023.6.2',
  //   contents: ["【新增】复制到输入框的图片将直接发送给对方（可利用输入法的表情包复制功能发生表情包）"],
  // },
  // {
  //   title:'2023.6.1',
  //   contents: ["【新增】发送语音已支持，目前仅支持谷歌、火狐、Edge、Safari浏览器"],
  // },
  // {
  //   title:'2023.5.25',
  //   contents: ["【新增】瓶子功能恢复，内容将经过人工审核", "【修复】部分浏览器验证码验证不通过的问题"],
  // },
  // {
  //   title:'2023.5.19',
  //   contents: ["【新增】夜间模式(深色模式) 可在设置页开启", "【完善】绿色模式的敏感词库"],
  // },
  // {
  //   title:'2023.5.18',
  //   contents: ["【新增】修改个人信息增加限制", "【新增】魅力值大于15可关闭绿色模式", "【优化】加快聊天获取魅力值的速度", "【完善】绿色模式的敏感词库"],
  // },
  // {
  //   title:'2023.5.17',
  //   contents: ["【新增】新用户进入需要进行人机验证", "【完善】绿色模式的敏感词库"],
  // },
  // {
  //   title:'2023.5.16',
  //   contents: ["下线漂流瓶群聊功能", "暂时下线发送文件的功能"],
  // },
  // {
  //   title:'2023.5.13',
  //   contents: ["【新增】陌生人设置支持设置年龄段", "【新增】年龄小于18岁强制开启绿色模式"],
  // },
  // {
  //   title:'2023.5.11',
  //   contents: ["【新增】敏感词，并封禁相关用户", "删除违规头像，封禁相关用户"],
  // },
  // {
  //   title:'2023.5.9',
  //   contents: ["【新增】上传自定义头像", "【修复】上传文件前检查次数", "【修复】部分敏感词库误识别的问题"],
  // },
  // {
  //   title:'2023.5.8',
  //   contents: ["【新增】扔瓶子 不合规的内容将消耗次数，同时不会被捡到", "【修复】部分敏感词库误识别的问题"],
  // },
  // {
  //   title:'2023.5.7',
  //   contents: ["【新增】聊天输入框emoji选择器", "【新增】遇见页 - 更新日志", "【新增】捡瓶子根据绿色模式过滤", "【修复】emoji只能选择一个的问题", "【完善】部分样式展示", "【修复】部分敏感词库误识别的问题"],
  // },
  // {
  //   title:'2023.5.6',
  //   contents: ["【新增】设置页 - 英语模式", "【修复】捡瓶子后，聊天列表内最后条消息未显示", "【修复】部分敏感词库误识别的问题", "【完善】对方信息卡片展示样式", "【清理】存在敏感词的历史昵称"],
  // },
  // {
  //   title:'2023.5.5',
  //   contents: ["【新增】设置页 - 绿色模式", "【新增】敏感词库", "【新增】聊天默认屏蔽部分敏感词", "【新增】对方信息卡片 - 举报入口", "【修复】拉黑可能导致的误判"],
  // },
  // {
  //   title:'2023.5.4',
  //   contents: ["【新增】随机匹配模块重构", "【新增】匹配成功后，对方信息卡片 - 重新匹配"],
  // },
  // {
  //   title:'2023.5.3',
  //   contents: ["【修复】随机匹配可能匹配到多人","【修复】删除聊天后最后一条消息未清空", "【新增】设置页 - 常见问题"],
  // },
  // {
  //   title:'2023.5.2',
  //   contents: ["【完善】数据清理策略，详情见《服务条款》", "【新增】捡瓶子展示对方信息" , "【完善】注册账号不再赠送魅力值，新用户进入第二天即可获得基础使用次数" , "【新增】女生增加已认证的标签", "【删除】新人接待公共群聊 (因为管理及性能问题)"],
  // },
  // {
  //   title:'2023.5.1',
  //   contents: ["【新增】新人接待 功能群聊回答用户常见问题", "【新增】广播系统"],
  // },
  // {
  //   title:'2023.4.29',
  //   contents: ["【新增】群聊功能", "【修复】群聊功能用户反馈问题"],
  // },
  // {
  //   title:'2023.4.28',
  //   contents: ["【新增】匹配等待弹窗中给小提示", "【新增】限制文件上传数量（成本扛不住了啊喂！）", "【新增】每天从服务器删除上传的文件", "【修复】用户状态异常导致的页面消失"],
  // },
  // {
  //   title:'2023.4.27',
  //   contents: ["【新增】自动封禁模块", "【修复】捞个在线 可能会捞到被拉黑的用户", "【修复】安卓系统发文件的bug", "【新增】更多的头像 更多的话题", "【修复】部分操作反应过慢"],
  // },
]


