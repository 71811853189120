import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@/services/i18n'
import { appWidth } from '@/const/config'
import i18n from 'i18next'

i18n.changeLanguage('zh_CN')

const setAppHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  doc.style.setProperty('--app-width', appWidth)
}
window.addEventListener('resize', setAppHeight)
setAppHeight()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)