import React from 'react'
import { Form, Button, List, Input, Toast } from 'antd-mobile'
import EditDrawer from './EditDrawer'
import PropTypes from 'prop-types'
import { getNicknameApi } from '@/services/user'

export default function UserNicname(props) {
  const { nickname, onChange } = props
  const [open, setOpen] = React.useState(false)
  const formRef = React.useRef(null)

  const handleClickRandom = async () => {
    const { data, code } = await getNicknameApi()
    if (code !== 200) return
    formRef.current?.setFieldsValue({ nickname: data })
  }

  const handleSave = async () => {
    const value = formRef.current.getFieldValue()
    if (!value.nickname || value.nickname.length === 0) {
      Toast.show({ icon: 'fail', content: '昵称不能为空' })
      return
    }
    if (value.nickname.length > 10) {
      Toast.show({ icon: 'fail', content: '设置的昵称过长' })
      return
    }
    const { code } = await onChange({ nickname: value.nickname })
    if (code === 200) {
      setOpen(false)
    }
  }

  function handleClose() {
    formRef.current?.setFieldsValue({ nickname: nickname })
    setOpen(false)
  }

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => setOpen(true)}
        extra={<span>{nickname}</span>}>
        <span style={{ color: 'var(--theme-text-color)' }}>昵称</span>
      </List.Item>

      <EditDrawer
        height="50%"
        onClickSave={handleSave}
        title="设置昵称"
        anchor="bottom"
        open={open}
        onClose={handleClose}>
        <span style={{ fontSize: '10px', color: 'red', marginLeft: '16px' }}>
          每天只能修改1次，昵称将经过人工二次审核，违规内容将被处罚
        </span>
        <Form ref={formRef}>
          <Form.Item
            name="nickname"
            initialValue={nickname}
            rules={[
              {
                min: 1,
                max: 10,
                type: 'string',
                message: '昵称长度在10以内',
              },
            ]}
            extra={
              <Button
                onClick={handleClickRandom}
                size="small"
                style={{ marginLeft: '10px' }}
                color="primary"
                fill="none">
                随机
              </Button>
            }>
            <Input />
          </Form.Item>
        </Form>
      </EditDrawer>
    </>
  )
}

UserNicname.propTypes = {
  nickname: PropTypes.string.isRequired,
}
