import React, { useEffect, useRef } from 'react'
import { List, Modal, Input, Form, Toast } from 'antd-mobile'
import { getCallmeTokenApi, generateCallmeTokenApi, deleteCallmeTokenApi } from '@/services/user'
import copy from 'copy-to-clipboard'

export default function UserCallme(props) {
  const { user } = props
  const formRef = useRef(null)
  const [isDisabled, setDisabled] = React.useState(false)

  // useEffect(() => {
  //   if (user.is_vip || user.is_admin) {
  //     setDisabled(false)
  //   }
  // }, [user.is_admin, user.is_vip])

  async function handleClick() {
    const { code, data } = await getCallmeTokenApi()
    if (code !== 200) return
    if (data) {
      setTimeout(() => {
        formRef.current?.setFieldsValue({ token: data.token })
      }, 100)
    }
    Modal.show({
      closeOnMaskClick: true,
      title: '分享链接',
      content: (
        <>
          <div style={{ fontSize: 10, color: 'var(--theme-text-color)' }}>
            <h3>提示:</h3>
            <p>1. 获取链接后，对方从该链接进入即可与你发起聊天</p>
            <p>2. 删除链接后，对方将无法再通过旧链接找到你</p>
            {isDisabled && <p style={{ textAlign: 'center', color: 'red' }}>开通VIP后方可使用</p>}
          </div>
          <Form ref={formRef}>
            <Form.Item name="token" initialValue="">
              <Input disabled />
            </Form.Item>
          </Form>
        </>
      ),
      onAction: handleAction,
      closeOnAction: true,
      actions: [
        {
          key: 'get',
          text: '获取链接',
          primary: true,
          disabled: isDisabled,
        },
        {
          key: 'delete',
          text: '删除链接',
        },
      ],
    })
  }

  async function handleAction(e) {
    if (e.key === 'get') {
      let token = formRef.current?.getFieldValue('token')
      if (!token || token === '') {
        const { data, code, msg } = await generateCallmeTokenApi()
        if (code !== 200) {
          Toast.show({ icon: 'fail', content: msg })
          return
        }
        token = data.token
      }
      // 复制到剪切板
      copy(`${window.location.origin}/chat?call=${token}`)
      Toast.show('你的链接已复制到剪切板，快粘贴发送给好友吧~')
    } else if (e.key === 'delete') {
      const token = formRef.current?.getFieldValue('token')
      if (!token || token === '') return
      const { code } = await deleteCallmeTokenApi()
      if (code === 200) {
        Toast.show('链接已删除，其他人将无法通过旧链接再找到你')
      }
    }
  }

  return (
    <>
      <List.Item style={{ textDecoration: 'none', lineHeight: '40px' }} onClick={handleClick}>
        <span style={{ color: 'var(--adm-color-primary)' }}>分享链接（对方打开链接即可与你聊天）</span>
      </List.Item>
    </>
  )
}
