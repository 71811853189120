import React, { useEffect } from 'react'
import { Form, List, Input, Toast, Space, Tag, Tabs, Selector, Dialog } from 'antd-mobile'

import EditDrawer from './EditDrawer'
import './UserTags.css'

export default function UserTags(props) {
  const { tags, onChange } = props
  const [open, setOpen] = React.useState(false)

  const [value, setValue] = React.useState([])

  useEffect(() => {
    if (open) {
      setValue(tags)
    }
  }, [open])

  const handleSave = async () => {
    const { code } = await onChange({ tags: value })
    if (code === 200) {
      setOpen(false)
    }
  }

  async function handleClose() {
    if (open) {
      var isChange = false
      ;(value || []).forEach((e) => {
        if ((tags || []).indexOf(e) === -1) isChange = true
      })
      if (isChange || (value || []).length !== (tags || []).length) {
        const result = await Dialog.confirm({
          title: '提示',
          content: '本次编辑尚未保存',
          cancelText: '放弃修改',
          confirmText: '保存',
        })
        if (result) {
          handleSave()
        }
      }
    }
    setOpen(false)
  }

  function handleChangeTags(options) {
    return (arr) => {
      let newTags = []
      ;(value || []).forEach((e) => {
        if (options.indexOf(e) === -1) {
          newTags.push(e)
        }
      })

      const aa = Array.from(new Set(newTags.concat(arr)))
      if (aa.length > 20) {
        Toast.show('最多只能添加20个标签~')
        return
      }
      setValue(aa)
    }
  }

  function handleRemoveTag(tag) {
    return () => {
      let newTags = []
      ;(value || []).forEach((e) => {
        if (e !== tag) {
          newTags.push(e)
        }
      })
      setValue(Array.from(new Set(newTags)))
    }
  }

  function getOption(arr) {
    let options = []
    arr.forEach((e) => {
      options.push({ label: e, value: e })
    })
    return options
  }
  const option1 =
    '学霸,学渣,文科生,理科生,美术生,体育生,艺术生,留学生,coser,上班族,IT从业者,传媒从业者,教育从业者,建筑从业者,广告从业者,创业者,插画家,化妆师,摄影师,工程师,设计师,销售,项目经理,程序员,HR,幼教,老师,导游,策划,酒店管理,老板,店主,厨师,手工艺人,托尼老师,家庭主妇,家庭煮夫,自由职业,声优,UP主,同人圈写手,rapper,字幕组翻译'.split(
      ','
    )
  const option2 =
    'K歌之王,喜欢独处,每年至少一次旅行,搓麻将,运动神经满分,知晓中华上下五千年,行走的美食攻略,不戴耳机不能出门,办公室茶农,朋友圈摄影师,数码产品技术宅,闲时喜垂钓,球场少不了我,护肤达人,鬼畜玩梗选手,口红可以摆一排,一言不合就rap,一年50本书,有轰趴的地方就有我,土味情话贩卖机,琴棋书画样样会,剧本杀智商担当,桌游小天才,喜欢穿汉服上街,广场舞dancer,云吸猫吸狗,社交小达人,老二次元肥宅,快乐铲屎官,从诗词歌赋谈到人生哲学'.split(
      ','
    )
  const option3 =
    '创过业,独立旅行过,在山顶看过日出,写过歌,露宿过街头,减肥超过20斤,当过群演,组过乐队,做过up主,办过展览,拿过奖学金,拍过微电影,跑过马拉松,写过小说,出过画集,玩过蹦极,看过极光,创建过社团,发表过文章,旅行打卡五大洲,骑车上万公里,护照已打满一本,留学多年'.split(
      ','
    )
  const option4 =
    '已读就回,打开正确即话痨,永远在减肥,间歇性高冷,铁憨憨,搞笑女,直男,直女,失眠惯犯,心理年龄只有三岁,社恐自闭小孩,抬杠艺术家,中二病患者,退堂鼓十级选手,做好发型再出门,零食测评家,理论大师实践废材,闻到香菜当场歇菜,每天和宿管斗智斗勇,天然呆,毒舌且温柔,矛盾文学大师,唠嗑型选手,完美主义,强迫症患者,懒癌晚期患者'.split(
      ','
    )
  const option5 =
    '吉他,钢琴,电音,说唱,古筝,后摇,尤克里里,古琴,流行乐,小提琴,大提琴,爵士乐,架子鼓,萨克斯,贝斯,kpop,民谣,中华小曲库,什么类型的音乐都听点儿,钟爱老歌,livehouse常客,混迹音乐节,一把吉他走天下,喜欢随机播放,五音不全但热爱唱歌,偏爱欧美流行,精通多种乐器'.split(
      ','
    )
  const option6 =
    '国产剧,美剧,日剧,韩剧,话剧,综艺,英剧,纪录片,动作片,冒险片,喜剧片,剧情片,科幻片,恐怖片,爱情片,悬疑片,犯罪片,请回答1988,爱王家卫,爱写影评,心灵捕手,日常追剧,Netfix网剧,看美剧学英语,DC,漫威,爱看小众文艺片,老友记忠粉'.split(
      ','
    )
  const option7 =
    '王者荣耀,吃鸡,LOL,原神,Steam,摩尔庄园,最终幻想,光之崽种,塞尔达,宝可梦,狼人杀,Dota,阴阳师,剑三,DNF,穿越火线,csgo,我的世界,动物餐厅,落地成盒,痛击我队友,手柄玩家,音游手残了,Terraria,Among Us,BOKU BOKU,Crash of Cars,Mindustry,逃跑吧! 少年,第五人格,蛋仔派对'.split(
      ','
    )
  const option8 =
    '瑜伽,篮球,台球,足球,羽毛球,乒乓球,骑行,跑步,游泳,爬山,撸铁狂魔,NBA,坚持夜跑,短跑小猎豹,各类球类多少沾点,野球场球王,每周必举铁,健身也养生,滑板,极限运动,喜欢拳击,网球,科比是一生偶像,库里忠粉,湖人总冠军,曼联,三分王'.split(
      ','
    )
  const option9 =
    '文学,哲学,历史,国漫,推理,小说,散文,国学,科幻,悬疑,诗歌,奇幻,武侠,童话,日漫,古典文学,网络小说,金庸,穿越,魔幻,鲁迅,余华,韩寒,刘慈欣,盗墓笔记,东野圭吾,周末泡图书馆,kindle形影不离,渴望结交书友,爱逛书店,熬鸡汤小能手'.split(
      ','
    )
  const option10 =
    '热血漫,校园漫,后宫漫,战斗冒险,后宫漫,治愈漫,ACG通吃,出没各种漫展,轻小说,家中摆满手办,新海诚,动漫,海贼王,JK制服,弹幕即本体,老番考古学家,汉化组打杂工,宫崎骏,夏目友人帐'.split(
      ','
    )
  const option11 =
    '相声演出,线下剧本杀,酒吧蹦迪,脱口秀,电影院,艺术展览,livehouse,户外露营,KTV,密室桌游,美食聚餐,城市徒步,露营野餐,逛街shopping'.split(
      ','
    )

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => setOpen(true)}
        extra={<span>查看</span>}>
        <span style={{ color: 'var(--theme-text-color)' }}>标签</span>
      </List.Item>

      <EditDrawer
        height="100%"
        onClickSave={handleSave}
        title="设置标签"
        anchor="bottom"
        open={open}
        onClose={handleClose}>
        <div style={{ padding: '6px 20px 0px 20px' }}>
          {/* <div style={{ fontSize: 10, color: 'var(--adm-color-danger)', marginBottom: 14, textAlign: 'center' }}>
            <span>首次增加标签，且标签数量大于10将获得30魅力值的奖励</span>
          </div> */}

          <div style={{ marginBottom: '30px' }} className="edit-wrapper">
            <Space wrap style={{ '--gap': '10px' }}>
              {(value || []).map((e) => {
                return (
                  <Tag
                    key={e}
                    round
                    color="#2db7f5"
                    style={{ fontSize: 16, cursor: 'pointer' }}
                    onClick={handleRemoveTag(e)}>
                    {e} x
                  </Tag>
                )
              })}
            </Space>
          </div>

          <Tabs defaultActiveKey="1">
            <Tabs.Tab title="职业" key="1">
              <Selector
                onChange={handleChangeTags(option1)}
                multiple
                showCheckMark
                options={getOption(option1)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="兴趣" key="2">
              <Selector
                onChange={handleChangeTags(option2)}
                multiple
                showCheckMark
                options={getOption(option2)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="特殊经历" key="3">
              <Selector
                onChange={handleChangeTags(option3)}
                multiple
                showCheckMark
                options={getOption(option3)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="个人特质" key="4">
              <Selector
                onChange={handleChangeTags(option4)}
                multiple
                showCheckMark
                options={getOption(option4)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="音乐" key="5">
              <Selector
                onChange={handleChangeTags(option5)}
                multiple
                showCheckMark
                options={getOption(option5)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="影视" key="6">
              <Selector
                onChange={handleChangeTags(option6)}
                multiple
                showCheckMark
                options={getOption(option6)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="游戏" key="7">
              <Selector
                onChange={handleChangeTags(option7)}
                multiple
                showCheckMark
                options={getOption(option7)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="运动" key="8">
              <Selector
                onChange={handleChangeTags(option8)}
                multiple
                showCheckMark
                options={getOption(option8)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="阅读" key="9">
              <Selector
                onChange={handleChangeTags(option9)}
                multiple
                showCheckMark
                options={getOption(option9)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="二次元" key="10">
              <Selector
                onChange={handleChangeTags(option10)}
                multiple
                showCheckMark
                options={getOption(option10)}
                value={value || []}
              />
            </Tabs.Tab>
            <Tabs.Tab title="线下兴趣活动" key="11">
              <Selector
                onChange={handleChangeTags(option11)}
                multiple
                showCheckMark
                options={getOption(option11)}
                value={value || []}
              />
            </Tabs.Tab>
          </Tabs>
        </div>
      </EditDrawer>
    </>
  )
}
