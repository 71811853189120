import React from 'react'
import { Form, List, Input, Toast } from 'antd-mobile'

import EditDrawer from './EditDrawer'

export default function UserAge(props) {
  const { age, onChange } = props
  const [open, setOpen] = React.useState(false)

  const formRef = React.useRef(null)

  const handleSave = () => {
    formRef.current
      .validateFields()
      .then(async (value) => {
        // 验证通过后进入
        const { code } = await onChange({ age: Number(value.age) })
        if (code === 200) {
          setOpen(false)
        }
      })
      .catch((err) => {
        // 验证不通过时进入
        Toast.show({ icon: 'fail', content: '输入内容有误' })
      })
  }

  function handleClose() {
    formRef.current?.setFieldsValue({ age: age || 0 })
    setOpen(false)
  }

  return (
    <>
      <List.Item
        style={{ textDecoration: 'none', lineHeight: '40px' }}
        onClick={() => setOpen(true)}
        extra={<span>{age}</span>}>
        <span style={{ color: 'var(--theme-text-color)' }}>年龄</span>
      </List.Item>

      <EditDrawer
        height="50%"
        onClickSave={handleSave}
        title="设置年龄"
        anchor="bottom"
        open={open}
        onClose={handleClose}>
        <span style={{ fontSize: '10px', color: 'red', marginLeft: '16px' }}>每天只能修改1次</span>
        <Form ref={formRef}>
          <Form.Item
            name="age"
            initialValue={age || 0}
            rules={[
              {
                max: 70,
                min: 18,
                type: 'number',
                message: '请输入18-70间的数字',
                transform: (value) => {
                  return Number(value)
                },
              },
            ]}>
            <Input />
          </Form.Item>
        </Form>
      </EditDrawer>
    </>
  )
}
